<template>
  <div style="background=color: #ffffff">
    <template v-if="isWxApplets">
      <van-nav-bar
        title="商品详情"
        fixed
        left-arrow
        @click-left="onClickLeft"
        z-index="200"
      />
    </template>
    <div class="goodsdetails" :style="{ marginTop: isWxApplets ? '46px' : '' }">
      <swiper
        ref="mySwiper"
        :class="list.length == 1 ? 'swiper-no-swiping' : ''"
        :options="swiperOptions"
      >
        <swiper-slide v-for="(item, index) in list" :key="index">
          <img
            v-if="!item.video"
            class="imgs previewer-demo-img"
            :src="item.src"
            @click="handlePreview(list, index)"
            alt=""
          />
          <div v-else class="video">
            <video
              :x5-playsinline="true"
              :webkit-playsinline="true"
              :playsinline="true"
              controls
            >
              <source :src="item.src" />
            </video>
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>

      <div class="top">
        <!-- <img
        class="left"
        @click="$router.back(-1)"
        src="../../../assets/ShopImg/left_w.png"
        alt=""
      /> -->
        <img
          class="fx"
          src="../../../assets/ShopImg/fx.png"
          alt=""
          @click="goShare"
        />
      </div>

      <!-- 内容 -->

      <div class="content">
        <!-- 价格 -->
        <div class="money">
          <div class="money_1">
            <span v-if="detail.DefaultPoints > 0"
              >{{ detail.DefaultPoints }}积分</span
            >
            {{ detail.DefaultPoints > 0 && detail.DefaultPrice > 0 ? "+" : "" }}
            <span v-if="detail.DefaultPrice > 0">
              ￥{{ detail.DefaultPrice }}</span
            >
            <span>￥{{ detail.DefaultOriginalPrice }}</span>
          </div>
          <div class="money_2">销量{{ detail.BuyCount }}</div>
        </div>

        <!-- 优惠 -->
        <div class="Discount" v-if="couponTitle">
          <span>{{ couponTitle }}</span>
        </div>

        <div class="contents">{{ detail.GoodsName }}</div>

        <div @click="GetCoupons" class="lq" v-if="couponTitle">
          <div>领券</div>
          <img src="../../../assets/ShopImg/right3.png" alt="" />
        </div>

        <van-popup
          v-model="show"
          round
          closeable
          position="bottom"
          :style="{ height: '60%' }"
        >
          <div class="pop">
            <div class="pop_title">优惠</div>

            <div class="yh">
              <div class="yh_content" v-for="(item, i) in couponList" :key="i">
                <div class="yh_content_money">
                  <p>
                    <span style="fontsize: 11px">￥</span
                    >{{ item.DiscountMoney }}
                  </p>
                  <p>满{{ item.FullMoney }}元可用</p>
                </div>
                <div class="yh_content_detail">
                  <p>{{ item.Title }}</p>
                  <p>有效期至{{ item.EndTime.split(" ")[0] }}</p>
                </div>
                <div class="btns" @click="postReceiveShopCoupon(item)">
                  立即领取
                </div>
                <img
                  v-if="item.IsReceive >= 1"
                  class="ylq"
                  src="../../../assets/ShopImg/ylq.png"
                  alt=""
                />
              </div>

              <van-button
                @click="NoneShow"
                block
                class="btns2"
                color="linear-gradient(99deg, #FC7134 0%, #F54024 100%)"
                round
                >关闭</van-button
              >
            </div>
          </div>
        </van-popup>
      </div>

      <!-- 规格 -->
      <div style="height: 10px; background-color: #f5f5f5"></div>
      <div class="specification">
        <van-cell
          title="规格"
          @click="SkuShow = true"
          :clickable="false"
          is-link
        >
          <template #title>
            <div class="custom-title" style="display: flex">
              规格<span
                class="decimalPoint"
                style="
                  display: inline-block;
                  width: 65vw;
                  color: #969799;
                  margin-left: 10px;
                "
                >{{ specName }}</span
              >
            </div>
          </template>
        </van-cell>
        <!-- <van-cell title="发货" value="浙江金华" /> -->
      </div>
      <!-- 规格详情 -->
      <van-sku
        v-model="SkuShow"
        :sku="sku"
        :goods="goods"
        :goods-id="goodsId"
        :quota="0"
        :quota-used="0"
        :preview-on-click-image="false"
        :hide-stock="sku.hide_stock"
        @buy-clicked="onBuyClicked"
        @add-cart="onAddCartClicked"
        @sku-selected="skuSelected"
      />

      <!-- 评论 -->

      <div style="height: 10px; background-color: #f5f5f5"></div>
      <div class="comment">
        <div class="comment_div">
          <!-- 评论数 -->
          <div class="comment_one">
            <div class="comment_number">评论({{ detail.CommentsCount }})</div>
            <router-link
              :to="'/shop/goods/comment/' + goodsId"
              class="comment_more"
            >
              查看更多
              <img src="../../../assets/ShopImg/right4.png" alt="" />
            </router-link>
          </div>

          <!-- 内容 -->

          <div
            class="comment_content"
            v-for="(item, i) in evaluationList"
            :key="i"
          >
            <!-- 资料 -->

            <div class="user">
              <img :src="hostUrl + item.HeadImg" alt="" />
              <div class="user_name">
                <p class="decimalPoint">{{ item.StudentName }}</p>
                <p>
                  {{
                    getDateDiff(getDateTimeStamp(item.EvaluateTime)) +
                    " | " +
                    item.SpecName
                  }}
                </p>
              </div>
            </div>

            <!-- 内容 -->

            <div class="comment_cont">
              {{ item.Evaluation }}
            </div>

            <!-- 图片 -->
            <div class="image" v-if="item.EvaluationImg">
              <div
                class="imgs"
                v-for="(item, i) in item.EvaluationImg.split(',')"
                :key="i"
              >
                <img :src="item" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 商品详情 -->
      <div style="height: 10px; background-color: #f5f5f5"></div>
      <div class="goods_detail">
        <div class="goods_title">商品详情</div>
        <p v-html="detail.Introduce"></p>
      </div>
    </div>

    <div style="height: 50px"></div>
    <van-goods-action>
      <van-goods-action-icon icon="service-o" text="客服" @click="goService" />
      <van-badge :dot="dotShow">
        <van-goods-action-icon icon="cart-o" text="购物车" @click="goShopCar" />
      </van-badge>
      <!-- <div style="width: 30%"></div> -->
      <van-goods-action-button
        class="goods_action"
        text="加入购物车"
        @click="SkuShow = true"
      />
      <van-goods-action-button
        class="goods_action_gm"
        text="立即购买"
        @click="SkuShow = true"
      />
    </van-goods-action>
    <!-- 预览图片 -->
    <previewer ref="previewer" :list="list" :options="options"> </previewer>
  </div>
</template>

<script>
import {
  queryShopCartList,
  queryShopGoodsDetail,
  queryCouponList,
  receiveShopCoupon,
  queryGoodsEvaluation,
  shopCartAdd,
} from "@/api/shop";
import { queryStudentInfoById } from "@/api/bbs";
import { Toast, ImagePreview } from "vant";
import vuePicturePreview from "vue-picture-preview";

export default {
  components: {
    Previewer: vuePicturePreview,
  },
  data() {
    return {
      isWxApplets: true,
      hostUrl: "",
      goodsId: 0,
      detail: {},
      couponList: [],
      evaluationList: [],
      userInfo: {},
      specName: "",
      couponTitle: "",
      msg: "<img style='width:100%;height:600px' src='https://img01.yzcdn.cn/vant/cat.jpeg' alt=''></img>",
      list: [],
      show: false, //领券显示
      SkuShow: false, //规格显示
      sku: {
        // 所有sku规格类目与其值的从属关系，比如商品有颜色和尺码两大类规格，颜色下面又有红色和蓝色两个规格值。
        // 可以理解为一个商品可以有多个规格类目，一个规格类目下可以有多个规格值。
        tree: [
          {
            k: "商品规格", // skuKeyName：规格类目名称
            k_s: "s1", // skuKeyStr：sku 组合列表（下方 list）中当前类目对应的 key 值，value 值会是从属于当前类目的一个规格值 id
            v: [
              //   {
              //     id: "1", // skuValueId：规格值 id
              //     name: "红色", // skuValueName：规格值名称
              //     imgUrl: "https://img01.yzcdn.cn/2.jpg", // 规格类目图片，只有第一个规格类目可以定义图片
              //     previewImgUrl: "https://img01.yzcdn.cn/2.jpg", // 用于预览显示的规格类目图片
              //   },
              //   {
              //     id: "2",
              //     name: "蓝色",
              //     imgUrl: "https://img01.yzcdn.cn/2.jpg",
              //     previewImgUrl: "https://img01.yzcdn.cn/2.jpg",
              //   },
            ],
            largeImageMode: false, //  是否展示大图模式
          },
        ],
        // 所有 sku 的组合列表，比如红色、M 码为一个 sku 组合，红色、S 码为另一个组合
        list: [
          //   {
          //     id: 2259, // skuId
          //     s1: "1", // 规格类目 k_s 为 s1 的对应规格值 id
          //     s2: "1", // 规格类目 k_s 为 s2 的对应规格值 id
          //     price: 100, // 价格（单位分）
          //     stock_num: 110, // 当前 sku 组合对应的库存
          //   },
          //   {
          //     id: 2260, // skuId
          //     s1: "2", // 规格类目 k_s 为 s1 的对应规格值 id
          //     s2: "1", // 规格类目 k_s 为 s2 的对应规格值 id
          //     price: 100, // 价格（单位分）
          //     stock_num: 110, // 当前 sku 组合对应的库存
          //   },
        ],
        price: "1.00", // 默认价格（单位元）
        stock_num: 227, // 商品总库存
        collection_id: 2261, // 无规格商品 skuId 取 collection_id，否则取所选 sku 组合对应的 id
        none_sku: false, // 是否无规格商品
        hide_stock: false, // 是否隐藏剩余库存
      },
      goods: {
        // 默认商品 sku 缩略图
        picture: "https://img01.yzcdn.cn/2.jpg",
      },
      // messageConfig: {
      //     // 图片上传回调，需要返回一个promise，promise正确执行的结果需要是一个图片url
      //     uploadImg: () => {
      //         return new Promise((resolve) => {
      //             setTimeout(() => resolve(
      //                 'https://img01.yzcdn.cn/upload_files/2017/02/21/FjKTOxjVgnUuPmHJRdunvYky9OHP.jpg!100x100.jpg'
      //                 ), 1000);
      //         });
      //     },
      //     // 最大上传体积 (MB)
      //     uploadMaxSize: 3,
      //     // placeholder 配置
      //     placeholderMap: {
      //         text: 'xxx',
      //         tel: 'xxx',
      //     },
      //     // 初始留言信息
      //     // 键：留言 name
      //     // 值：留言内容
      //     initialMessages: {
      //         留言: '留言信息'
      //     }
      // },
      customStepperConfig: {
        // 自定义限购文案
        quotaText: "每次限购xxx件",
        // 自定义步进器超过限制时的回调
        handleOverLimit: (data) => {
          const { action, limitType, quota, quotaUsed, startSaleNum } = data;

          if (action === "minus") {
            Toast(
              startSaleNum > 1 ? `${startSaleNum}件起售` : "至少选择一件商品"
            );
          } else if (action === "plus") {
            // const { LIMIT_TYPE } = Sku.skuConstants;
            if (limitType === LIMIT_TYPE.QUOTA_LIMIT) {
              let msg = `单次限购${quota}件`;
              if (quotaUsed > 0) msg += `，你已购买${quotaUsed}`;
              Toast(msg);
            } else {
              Toast("库存不够了");
            }
          }
        },
        // 步进器变化的回调
        handleStepperChange: (currentValue) => {},
        // 库存
        stockNum: 1999,
        // 格式化库存
        stockFormatter: (stockNum) => {},
      },
      initialSku: {
        // 键：skuKeyStr（sku 组合列表中当前类目对应的 key 值）
        // 值：skuValueId（规格值 id）
        s1: "1",
        // s2: "1",
        // 初始选中数量
        selectedNum: 1,
        // 初始选中的商品属性
        // 键：属性id
        // 值：属性值id列表
        // selectedProp: {
        //   123: [1222],
        // },
      },
      properties: [
        // 商品属性
        // {
        //   k_id: 123, // 属性id
        //   k: "加料", // 属性名
        //   is_multiple: true, // 是否可多选
        //   v: [
        //     {
        //       id: 1222, // 属性值id
        //       name: "珍珠", // 属性值名
        //       price: 1, // 属性值加价
        //     },
        //     {
        //       id: 1223,
        //       name: "椰果",
        //       price: 1,
        //     },
        //   ],
        // },
      ],
      swiperOptions: {
        loop: true,
        speed: 1000,
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //允许分页点击跳转
        },
      },
      options: {
        getThumbBoundsFn(index) {
          // find thumbnail element
          let thumbnail = document.querySelectorAll(".previewer-demo-img")[
            index
          ];
          // get window scroll Y
          let pageYScroll =
            window.pageYOffset || document.documentElement.scrollTop;
          // optionally get horizontal scroll
          // get position of element relative to viewport
          let rect = thumbnail.getBoundingClientRect();
          // w = width
          return { x: rect.left, y: rect.top + pageYScroll, w: rect.width };
          // Good guide on how to get element coordinates:
          // http://javascript.info/tutorial/coordinates
        },
      },
      dotShow: false,
    };
  },
  created() {
    console.log(this.$route.query);
    //判断是否是微信浏览器的函数
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      this.isWxApplets = false;
    }
    this.goodsId = this.$route.query.id;
    var that = this;
    window["callByAndroidParam"] = function (jsonStr) {
      if (jsonStr != "") {
        that.userInfo.Id = jsonStr.data;
        that.getQueryUserInfo();
        this.getQueryShopCartList();
      } else {
        this.$message.error("登录失败");
      }
    };
  },
  mounted() {
    if (this.$route.query.UserId == "") {
      localStorage.removeItem("userInfo");
    } else if (
      this.$route.query.UserId != undefined &&
      this.$route.query.UserId != ""
    ) {
      this.userInfo.Id = this.$route.query.UserId;
      this.getQueryUserInfo();
    } else {
      if (localStorage.getItem("userInfo") != null) {
        this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
      } else {
        this.userInfo.Id = 0;
      }
      this.getQueryShopCartList();
    }
    this.getQueryShopGoodsDetail();
    this.getQueryCouponList();
    this.getQueryGoodsEvaluation();
    window.userIDResultByIos = this.userIDResultByIos;
    this.hostUrl = this.GLOBAL.hostUrl;
  },
  methods: {
    // 顶部返回
    onClickLeft() {
      window.history.go(-1);
    },
    userIDResultByIos(jsonStr) {
      if (jsonStr != "") {
        this.user.Id = jsonStr.data;
        this.getQueryUserInfo();
      } else {
        this.$message.error("登录失败");
      }
    },
    callApp() {
      if (this.isWxApplets) {
        let u = navigator.userAgent;
        let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
        if (window.android != null && typeof window.android != "undefined") {
          //可以传递自定义数据，比如json
          window.android.callAndroid(JSON.stringify({ action: "getUserID" }));
        } else if (isiOS) {
          window.webkit.messageHandlers.callIosGetUserID.postMessage(
            JSON.stringify({ action: "callIosGetUserID" })
          );
        }
      } else {
        console.log(localStorage.getItem("userInfo"));
        // that.userInfo.Id = '';
        // that.getQueryUserInfo();
        // this.getQueryShopCartList();
      }
    },

    // 购物车列表数据
    async getQueryShopCartList() {
      let parm = {
        pageIndex: 1,
        pageSize: 10,
        StudentId: this.userInfo.Id,
      };
      const res = await queryShopCartList(parm);
      this.dotShow = res.response.dataCount > 0 ? true : false;
    },
    // 用户详情
    async getQueryUserInfo() {
      const res = await queryStudentInfoById(this.userInfo.Id);
      this.userInfo = res.response;
      localStorage.setItem("userInfo", JSON.stringify(this.userInfo));
    },
    handlePreview(array, index) {
      // console.log(array, '')
      // ImagePreview({
      //   images: array,
      //   closeable: true,
      //   startPosition: index,
      // });
      // 显示特定index的图片，使用ref
      this.$refs.previewer.show(index);
    },
    GetCoupons() {
      this.show = true;
    },
    goShare() {
      this.$router.push({
        path: "/shop/orders/shareImg",
        query: { goodsId: this.detail.Id },
      });
    },
    NoneShow() {
      this.show = false;
    },
    // 添加购物车
    async onAddCartClicked(e) {
      console.log(e);
      if (this.userInfo.Id == 0) {
        this.callApp();
        return false;
      }
      let params = {};
      params.StudentId = this.userInfo.Id;
      params.StudentName = this.userInfo.RealName;
      params.GoodsCount = e.selectedNum;
      params.SpecId = e.selectedSkuComb.id;
      params.GoodsId = this.goodsId;
      console.log(params);
      const res = await shopCartAdd(params);
      if (res.success == true) {
        // this.$message.success(res.msg);
        // 成功提示
        Toast.success({
          message: res.msg,
        });
      } else {
        // this.$message.error(res.msg);
        Toast.fail({
          message: res.msg,
        });
      }
      //   this.postShopCartAdd(params);
    },
    // 付款
    onBuyClicked(e) {
      if (this.userInfo.Id == 0) {
        this.callApp();
        return false;
      }
      let goodsData = [];
      let data = {};
      data.GoodsId = this.goodsId;
      data.SpecId = e.selectedSkuComb.id;
      data.Count = e.selectedNum;
      goodsData.push(data);
      if (goodsData.length == 0) {
        Toast.fail({
          message: "请选择要结算的商品",
        });
        return false;
      }
      this.$router.push({
        path: "/shop/orders/confirmOrder",
        query: { goodsData: JSON.stringify(goodsData) },
      });
    },
    //选择规格
    skuSelected(e) {
      console.log(e);
      this.specName = e.skuValue.name;
      this.detail.DefaultPrice = e.selectedSkuComb.price / 100;
      this.detail.DefaultOriginalPrice = e.selectedSkuComb.oprice;
    },
    // 获取商品详情
    async getQueryShopGoodsDetail() {
      const res = await queryShopGoodsDetail(this.goodsId);
      this.detail = res.response;
      this.detail.Images = this.detail.Images.split(",");
      this.detail.Images.forEach((element) => {
        var flieArr = element.split(".");
        var suffix = flieArr[flieArr.length - 1];
        var videolist = ["mp4", "m2v", "mkv"];
        var result = videolist.some((item) => {
          return item == suffix;
        });
        this.list.push({
          video: result,
          msrc: element,
          src: element,
          h: 375,
        });
      });

      // 组装规格数据
      this.sku.price = this.detail.DefaultPrice;
      var spec = this.detail.GoodsSpecList;
      for (var i = 0; i < spec.length; i++) {
        if (spec[i].IsDefault == 1) {
          this.sku.stock_num = spec[i].Stock;
          this.goods.picture = spec[i].ImageUrl;
          this.specName = spec[i].SpecName;
          this.initialSku.s1 = spec[i].Id;
        }
        let obj = {};
        obj.id = spec[i].Id;
        obj.name = spec[i].SpecName;
        obj.imgUrl = spec[i].ImageUrl;
        obj.previewImgUrl = spec[i].ImageUrl;
        this.sku.tree[0].v.push(obj);
        let obj2 = {};
        obj2.id = spec[i].Id;
        obj2.s1 = spec[i].Id;
        // obj2.s2 = spec[i].Id;
        obj2.price = spec[i].Price * 100;
        obj2.oprice = spec[i].OriginalPrice;
        obj2.stock_num = spec[i].Stock;
        this.sku.list.push(obj2);
      }
      // console.log(this.initialSku);
    },
    // 获取优惠券列表
    async getQueryCouponList() {
      let params = {};
      params.Status = 0;
      params.GoodsId = this.goodsId;
      params.pageIndex = 1;
      params.pageSize = 100;
      params.UserId = this.userInfo.Id;
      const res = await queryCouponList(params);
      this.couponList = res.response.data;
      this.couponTitle =
        this.couponList.length > 0
          ? "满" +
            this.couponList[0].FullMoney +
            "减" +
            this.couponList[0].DiscountMoney
          : "";
      console.log(this.couponList);
    },
    // 领取优惠券
    async postReceiveShopCoupon(item) {
      if (this.userInfo.Id == 0) {
        this.callApp();
        return false;
      }
      let params = {};
      params.ShopCouponId = item.Id;
      params.UserId = this.userInfo.Id;
      params.UserName = this.userInfo.RealName;
      const res = await receiveShopCoupon(params);
      if (res.success == true) {
        Toast.success({
          message: res.msg,
          position: "bottom",
        });
        item.IsReceive = 1;
      } else {
        Toast({
          message: res.response,
          position: "bottom",
        });
      }
    },
    // 加入购物车
    async postShopCartAdd(params) {
      const res = await shopCartAdd(params);
      if (res.success == true) {
        this.$message.success(res.msg);
      } else {
        this.$message.error(res.msg);
      }
    },
    // 获取评论列表
    async getQueryGoodsEvaluation() {
      let params = {};
      params.GoodsId = this.goodsId;
      params.pageIndex = 1;
      params.pageSize = 2;
      const res = await queryGoodsEvaluation(params);
      this.evaluationList = res.response.data;
      // console.log(this.evaluationList);
    },
    goShopCar() {
      console.log(this.userInfo);
      if (this.userInfo.Id == 0) {
        this.callApp();
        return false;
      }
      this.$router.push({
        path: "/shop/ShopCar",
        query: {},
      });
    },
    goService() {
      window.location.href =
        "https://work.weixin.qq.com/kfid/kfc8db1b9998eef1aba";
    },
    getDateTimeStamp(dateStr) {
      return Date.parse(dateStr.replace(/-/gi, "/"));
    },
    getDateDiff(dateTimeStamp) {
      let result = "";
      var minute = 1000 * 60;
      var hour = minute * 60;
      var day = hour * 24;
      var halfamonth = day * 15;
      var month = day * 30;
      var now = new Date().getTime();
      var diffValue = now - dateTimeStamp;
      if (diffValue < 0) {
        //若日期不符则弹窗口告之,结束日期不能小于开始日期！
      }
      var monthC = diffValue / month;
      var weekC = diffValue / (7 * day);
      var dayC = diffValue / day;
      var hourC = diffValue / hour;
      var minC = diffValue / minute;
      if (monthC >= 1) {
        result = parseInt(monthC) + "个月前";
      } else if (weekC >= 1) {
        result = parseInt(weekC) + "周前";
      } else if (dayC >= 1) {
        result = parseInt(dayC) + "天前";
      } else if (hourC >= 1) {
        result = parseInt(hourC) + "小时前";
      } else if (minC >= 1) {
        result = parseInt(minC) + "分钟前";
      } else result = "刚刚";
      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
.goodsdetails {
  // height: calc(100vh - 50px);
  // overflow-y: scroll;
}
/deep/.van-badge {
  top: 4px;
  right: 15px;
}
.swiper-slide {
  // height: 375px;
  width: 100%;
  .video {
    position: relative;
    .van-icon {
      position: absolute;
    }
  }
  .video,
  video,
  .imgs {
    width: 100%;
    height: 100%;
    background-color: #000;
  }
}

.top {
  position: absolute;
  top: 25px;
  width: 92%;
  margin-left: 4%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 100;

  .left {
    width: 19px;
    height: 20px;
  }

  .fx {
    width: 19px;
    height: 19px;
  }
}

/deep/ .swiper-pagination-bullet-active {
  background: #fff;
}

.content {
  width: 100%;
  padding: 15px;
  background: #fff;
  position: relative;

  .money {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .money_1 span {
      font-size: 19px;
      color: #fe4a4a;
      font-weight: bold;
    }

    .money_1 span:last-child {
      font-size: 12px;
      color: #c9c9c9;
      font-weight: bold;
      text-decoration: line-through;
      margin-left: 10px;
    }

    .money_2 {
      color: #c9c9c9;
      font-size: 12px;
    }
  }

  .Discount {
    margin: 5px 0;

    span {
      display: inline-block;
      color: #fe4a4a;
      font-size: 8px;
      padding: 3px 6px 2px 6px;
      background: #ffffff;
      border: 1px solid #fe4a4a;
      border-radius: 30px;
      margin-bottom: 5px;
      line-height: 11px;
    }
  }

  .contents {
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 22px;
    color: #333333;
  }

  .lq {
    width: 50px;
    height: 25px;
    background: #000000;
    border-radius: 20px 0px 0px 20px;
    color: #fff;
    font-size: 8px;
    display: flex;
    align-items: center;
    text-align: center;
    position: absolute;
    right: 0;
    top: 47px;

    div {
      margin-left: 10px;
    }

    img {
      width: 8px;
      height: 9px;
      margin-left: 2px;
    }
  }
}

.van-cell__value {
  position: absolute;
  overflow: hidden;
  color: #969799;
  text-align: left;
  vertical-align: middle;
  word-wrap: break-word;
  left: 60px;
}

.specification {
  // margin-top: 10px;
}

.comment {
  width: 100%;
  // margin-top: 10px;
  background: #fff;

  .comment_div {
    padding: 0 15px;
  }

  .comment_one {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 30px;
    line-height: 30px;
    // padding-top: 20px;
    padding: 10px 0;

    .comment_number {
      color: #333333;
      font-size: 17px;
      font-weight: bold;
    }

    .comment_more {
      display: flex;
      align-items: center;
      color: #000000;
      font-size: 13px;

      img {
        width: 16px;
        height: 14px;
        margin-top: -3px;
      }
    }
  }

  .comment_content {
    padding-bottom: 15px;
    border-bottom: 1px solid #e8e8e8;
    width: 100%;

    .user {
      display: flex;
      align-items: center;
      margin-top: 15px;

      img {
        width: 42px;
        height: 42px;
        border-radius: 50%;
      }

      .user_name {
        margin-left: 10px;
      }

      .user_name p:first-child {
        font-size: 14px;
        width: 100px;
        color: #333333;
        font-weight: 400;
      }

      .user_name p:last-child {
        font-size: 12px;
        color: #999999;
        font-weight: 400;
      }
    }

    .comment_cont {
      font-size: 14px;
      color: #333333;
      font-weight: 400;
      line-height: 20px;
      margin-top: 12px;
    }

    .image {
      margin-top: 15px;
      display: flex;

      .imgs {
        position: relative;
        width: 20%;
        padding-top: 20%;
        margin-right: 5%;

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 4px;
        }
      }
    }
  }
}

.goods_detail {
  padding: 0 10px;
  // margin-top: 10px;
  background: #fff;
  max-width: 100vw !important;
  overflow: hidden;
  .goods_title {
    color: #333333;
    font-size: 17px;
    font-weight: bold;
    text-align: center;
    height: 70px;
    line-height: 70px;
  }
  /deep/ img {
    max-width: 100% !important;
  }
  /deep/ div {
    max-width: 100% !important;
  }
  /deep/ span {
    // display: block;
    max-width: 100% !important;
  }
  /deep/ p {
    max-width: 100% !important;
  }
}

.pop {
  padding: 0 15px;

  .pop_title {
    color: #333333;
    font-size: 16px;
    font-weight: 800;
    text-align: center;
    margin-top: 14px;
  }

  .yh {
    margin-top: 10px;
    padding-bottom: 15px;

    .yh_content {
      width: 100%;
      height: 100px;
      background-image: url("../../../assets/ShopImg/yh.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      box-shadow: 5px 4px 6px rgba(146, 146, 146, 0.16);
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      .yh_content_money {
        width: 32.5%;
        text-align: center;

        p {
          color: #fff;
        }
      }

      .yh_content_money p:first-child {
        font-size: 22px;
      }

      .yh_content_money p:last-child {
        font-size: 10px;
      }

      .yh_content_detail {
        margin-left: 10px;
      }

      .yh_content_detail p:first-child {
        color: #333333;
        font-size: 14px;

        font-weight: bold;
      }

      .yh_content_detail p:last-child {
        font-size: 8px;
        color: #666666;
        margin-top: 12px;
      }

      .btns {
        width: 60px;
        height: 24px;
        background: #f95e2e;
        position: absolute;
        top: 57%;
        left: 80%;
        border-radius: 20px;
        font-size: 8px;
        color: #fff;
        line-height: 24px;
        text-align: center;
      }
    }

    .btns2 {
      width: 80%;
      margin: 0 auto;
    }
  }
}

.ylq {
  position: absolute;
  width: 52px;
  height: 40px;
  right: 0;
  top: 0;
}

/deep/video::-webkit-media-controls-fullscreen-button {
  display: none;
}
.van-button {
  background: #fff;
  border: 1px solid #000;
  font-weight: 700;
}
.goods_action_gm {
  background: #000;
  color: #fff;
}
/deep/ .van-button--warning {
  background: #fff;
  color: #000;
  border: 1px solid #000;
}
/deep/ .van-button--danger {
  background: #000;
}
/deep/ .van-nav-bar .van-icon {
  color: #000;
  font-size: 20px;
}
</style>
